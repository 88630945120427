// Generated by Framer (91bcfbd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["msub8F1U8", "Rremtetrw"];

const serializationHash = "framer-4NiP7"

const variantClassNames = {msub8F1U8: "framer-v-vh2e9g", Rremtetrw: "framer-v-cuszxo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Menu: "msub8F1U8", X: "Rremtetrw"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, mP9Tz30oK: color ?? props.mP9Tz30oK ?? "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75))", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "msub8F1U8"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, mP9Tz30oK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "msub8F1U8", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap179c0q3 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("Rremtetrw")
})

const onTapzggv87 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("msub8F1U8")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vh2e9g", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"msub8F1U8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap179c0q3} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Rremtetrw: {"data-framer-name": "X", onTap: onTapzggv87}}, baseVariant, gestureVariant)}><motion.div className={"framer-albv9j"} data-framer-name={"Bottom Line"} layoutDependency={layoutDependency} layoutId={"yS1b0dwSU"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{Rremtetrw: {rotate: -45}}}/><motion.div className={"framer-1mrzd7"} data-framer-name={"Middle Line"} layoutDependency={layoutDependency} layoutId={"LJtTXHDkm"} style={{backgroundColor: mP9Tz30oK, opacity: 1}} variants={{Rremtetrw: {opacity: 0}}}/><motion.div className={"framer-ir82jn"} data-framer-name={"Top Line"} layoutDependency={layoutDependency} layoutId={"GZoWv03tL"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{Rremtetrw: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-4NiP7[data-border=\"true\"]::after, .framer-4NiP7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4NiP7.framer-12zfgyf, .framer-4NiP7 .framer-12zfgyf { display: block; }", ".framer-4NiP7.framer-vh2e9g { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-4NiP7 .framer-albv9j { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; width: 24px; }", ".framer-4NiP7 .framer-1mrzd7 { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }", ".framer-4NiP7 .framer-ir82jn { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: 7px; width: 24px; }", ".framer-4NiP7.framer-v-cuszxo.framer-vh2e9g { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-4NiP7.framer-v-cuszxo .framer-albv9j { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-4NiP7.framer-v-cuszxo .framer-1mrzd7 { left: calc(50.00000000000002% - 2px / 2); width: 2px; }", ".framer-4NiP7.framer-v-cuszxo .framer-ir82jn { left: unset; right: 4px; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Rremtetrw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","mP9Tz30oK":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerO_2XZOHKT: React.ComponentType<Props> = withCSS(Component, css, "framer-4NiP7") as typeof Component;
export default FramerO_2XZOHKT;

FramerO_2XZOHKT.displayName = "Menu Icon";

FramerO_2XZOHKT.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerO_2XZOHKT, {variant: {options: ["msub8F1U8", "Rremtetrw"], optionTitles: ["Menu", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, mP9Tz30oK: {defaultValue: "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75)) /* {\"name\":\"Background - Dark\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerO_2XZOHKT, [])